<template>
  <v-app class="lato-font">
    <div class="row marg-0 padd-t-128">
      <div class="col-md-7 col-sm-12 col-12  padd-0 h-304">
        <div class="row marg-0 align-center">
          <div class="marg-0">
            <div>
              <span class="fsize24 marg-0 clr-000000"
                >Let's start with your PAN
              </span>
            </div>
            <form @submit.prevent="paN">
              <div class="w-100 h-64 marg-t-32">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 l-height padd-l-8 clr-55585a">PAN</span>
                </div>
                <input
                  type="text"
                  class="w-336 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder="Please enter the PAN"
                  autocomplete="off"
                  id="pann"
                  value="pann"
                  @input="user.pann = $event.target.value.toUpperCase()"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model="user.pann"
                  :class="{
                    'is-invalid': submitted && $v.user.pann.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.pann.$error"
                  class="invalid-feedback marg-0"
                >
                  <span v-if="!$v.user.pann.required" class="validatemessage"
                    >Please Enter your PAN Number</span
                  >
                  <span v-if="!$v.user.pann.alphaNum" class="validatemessage"
                    >Allow only alphanumeric characters</span
                  >
                </div>
              </div>
                  </form>
                   <form @submit.prevent="paN">
              <div class="w-100 h-64 marg-t-24">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 l-height padd-l-8 clr-55585a">DOB</span>
                </div>
                <input
                  type="date"
                  class="w-336 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder="Please enter the DOB"
                  autocomplete="off"
                  v-model="user.dob"
                  :class="{
                    'is-invalid': submitted && $v.user.dob.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.dob.$error"
                  class="invalid-feedback marg-0"
                >
                  <span v-if="!$v.user.dob.required" class="validatemessage"
                    >Please Enter your DOB</span
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <button
            class="btn-color fsize18 padd-0-16 bor-radius h-40 marg-t-38 w-100per"
            @click="paN()"
          >
            Continue
          </button>
        </div>
      </div>
      <div class="col-md-5 col-sm-12   padd-0 hideimgmob">
        <img class="desktopimg1" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
    <hr class="lineBelowHead marg-0 marg-t-20" />

    <span class="fsize12 clr9b9b">
      <label class="marg-0 marg-t-16">
        Online account opening requires your mobile number to be linked to
        Aadhaar. You can check if your mobile number is linked to Aadhaar here.
        If your mobile number isn't linked to</label
      >
      <label class="marg-0">
        Aadhaar, please open your account offline.
        <span class="clr-0073bc"> Know more </span></label
      >.<label class="marg-0 marg-t-12">
        Online account opening involves using digilocker gov.in to obtain your
        identity proof if you are not already KRA verfied, and digitally signing
        your application form with Aadhaar</label
      >
      <label class="marg-0">
        eSign using Digio (licensed ASP). We do not collect or store your
        Aadhaar number, and neither of these services revea; upir Aadhaar number
        to us. If you do not wish to do this,</label
      >
      <label class="marg-0">
        please use our<span class="clr-0073bc"> offline forms </span> for
        account opening. Amoga does not perform eKYC and does not colect or
        store Aadhaar numbers.</label
      >
      <label class="marg-0 marg-t-12">
        Please Note: Your account name would be taken as per the name registered
        on the income Tax database / as per your PAN.</label
      >
      <label class="marg-0 marg-t-12 marg-b-16">
        If you are HUF, Corporate, Partnership, or an NRI, you have to use the
        <span class="clr-0073bc"> offline forms </span>. For help,
        <span class="clr-0073bc"> click here </span>.</label
      >
    </span>
  </v-app>
</template>

<script>
import { required, alphaNum } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        pann: this.pann,
        dob: this.dob,
      },
      pann: null,
      dob: null,
      submitted: true,
    };
  },
  validations: {
    user: {
      pann: { required, alphaNum },
      dob: { required },
    },
  },
  methods: {
    paN() {
      this.submitted = true;
      //Error catch user id is invalid//
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$router.push("/payaccount");
    },
  },

  mounted() {},
};
</script>

<style>
</style>